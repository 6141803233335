import React, { useEffect, useState } from "react";
import "bootswatch/dist/litera/bootstrap.min.css";
//import Formulario from "./Formulario";
import { db } from "../firebase/firebase";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import IngresarViajes from "./IngresarViajes";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import logo from '../images/logo.png';


const Planilla = () => {
  //Funcion para formatear moneda a pesos colombianos
  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const [viajes, setViajes] = useState([]);

  const addOrEditViajes = async (viajes) => {
    await addDoc(collection(db, "viajes"), {
      fecha: viajes.fecha,
      hora: viajes.hora,
      detalle: viajes.detalle,
      valorViaje: viajes.valorViaje,
      anticipo: viajes.anticipo,
      porcentajeConductor: viajes.porcentajeConductor,
      peaje: viajes.peaje,
      parqueo: viajes.parqueo,
      otrosGastos: viajes.otrosGastos,
      acpm: viajes.acpm,
      saldo: viajes.saldo,
      placa: viajes.placa,
    });
  };

  const getViajes = async () => {
    onSnapshot(collection(db, "viajes"), (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setViajes(docs);
    });
    console.log(viajes.id);
  };

  useEffect(() => {
    getViajes();
  }, []);

  /*  
  const columnas = [
    {
      label: "Fecha",
      name: "date",
      options: { sort: true, filter: true },
    },
    {
      label: "Detalle",
      name: "detalle",
      options: { sort: true, filter: true },
    },
    {
      label: "Valor del viaje",
      name: "valorViaje",
      options: { sort: true, filter: true },
    },
    {
      label: "Anticipo",
      name: "anticipo",
      options: { sort: true, filter: true },
    },
    {
      label: "Porcentaje del Conductor",
      name: "porcentajeConductor",
      options: { sort: true, filter: true },
    },
    {
      label: "Peaje",
      name: "peaje",
      options: { sort: true, filter: true },
    },
    {
      label: "Parqueo",
      name: "parqueo",
      options: { sort: true, filter: true },
    },
    {
      label: "Otros gastos",
      name: "otrosGastos",
      options: { sort: true, filter: true },
    },
    {
      label: "ACPM",
      name: "acpm",
      options: { sort: true, filter: true },
    },
    {
      label: "Saldo",
      name: "saldo",
      options: { sort: true, filter: true },
    },
  ];
  */

  const onDelete = (id) => {
    console.log(id);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary p-2">
          
        <a className="navbar-brand" href="#">
          <img src={logo} alt="" class="circle img-thumbnail" /> {"      "}
          <b>Transportes Posada Web</b>
        </a>

        <div
          className="collapse navbar-collapse"
          id="navbarText"
          align="center"
        >
          <span className="navbar-text">
            <b>Control de Transportes</b>
          </span>
        </div>
      </nav>
      <div className="container p-4">
        {/* Body */}
        <div className="row">
          <IngresarViajes addOrEditViajes={addOrEditViajes} />
          <div className="col-md-8 "></div>
        </div>

        {/* Tablaaa */}
        <div className="table-responsive " style={{ height: "0px" }}>
          <table
            id="tabla-planilla"
            className="table table-striped table-bordered table-hover"
          >
            <thead>
              <tr>
                <th scope="row">Fecha</th>
                <th>Vehículo</th>
                <th>Detalle</th>
                <th>Valor del viaje</th>
                <th>Anticipo</th>
                <th>Porcentaje del Conductor</th>
                <th>Peaje</th>
                <th>Parqueo</th>
                <th>Otros gastos</th>
                <th>ACPM</th>
                <th>Saldo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {viajes.map((viajes) => (
                <tr key={viajes.id}>
                  <th scope="row">{viajes.fecha + " " + viajes.hora}</th>
                  <td>{viajes.placa}</td>
                  <td>{viajes.detalle}</td>
                  <td> {viajes.valorViaje}</td>
                  <td>{viajes.anticipo}</td>
                  <td>{viajes.porcentajeConductor}</td>
                  <td>{viajes.peaje}</td>
                  <td>{viajes.parqueo}</td>
                  <td>{viajes.otrosGastos}</td>
                  <td>{viajes.acpm}</td>
                  <td>{viajes.saldo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container p-2" align="right">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-success"
            table="tabla-planilla"
            filename="Planilla"
            sheet="Planilla"
            buttonText="Exportar a excel"
            aling="right"
          />
        </div>
        <div className="table-responsive">
          {console.log(viajes.id)}
          <Table data={viajes} id="planilla" autoHeight>
            <Column width={150} sortable resizable fixed>
              <HeaderCell>
                <b>Fecha y hora</b>
              </HeaderCell>
              <Cell dataKey="fecha" value="fecha" />
            </Column>

            <Column width={100} sortable resizable fixed>
              <HeaderCell>
                <b>Placa</b>
              </HeaderCell>
              <Cell dataKey="placa" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Detalle</b>
              </HeaderCell>
              <Cell dataKey="detalle" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Valor del viaje</b>
              </HeaderCell>
              <Cell dataKey="valorViaje" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Anticipo</b>
              </HeaderCell>
              <Cell dataKey="anticipo" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Porcentaje del Conductor</b>
              </HeaderCell>
              <Cell dataKey="porcentajeConductor" />
            </Column>

            <Column width={70} resizable>
              <HeaderCell>
                <b>Peaje</b>
              </HeaderCell>
              <Cell dataKey="peaje" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Parqueo</b>
              </HeaderCell>
              <Cell dataKey="parqueo" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Otros Gastos</b>
              </HeaderCell>
              <Cell dataKey="otrosGastos" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>ACPM</b>
              </HeaderCell>
              <Cell dataKey="acpm" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Saldo</b>
              </HeaderCell>
              <Cell dataKey="saldo" />
            </Column>

            <Column width={100} resizable>
              <HeaderCell>
                <b>Acciones</b>
              </HeaderCell>
              <Cell align="center">
                <i className="material-icons">mode_edit</i>
                {"  "}
                <i
                  className="material-icons"
                  onClick={() => onDelete(viajes.id)}
                >
                  delete_forever
                </i>
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Planilla;
