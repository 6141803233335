import React, { Component } from "react";
import "./css/App.css";
import "./firebase/firebase";
import "bootswatch/dist/solar/bootstrap.min.css";
//import Tabla from "./components/Tabla";
import Planilla from "./components/Planilla";



class App extends Component {
  
  render() {

    return (
      <Planilla />
    );
  }
}

export default App;
