// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    const firebaseApp = initializeApp ({
        apiKey: "AIzaSyAWzsViSeF9zuUBjvHGx-P1fnVTSrl8rIA",
        authDomain: "posada-database.firebaseapp.com",
        databaseURL: "https://posada-database-default-rtdb.firebaseio.com",
        projectId: "posada-database",
        storageBucket: "posada-database.appspot.com",
        messagingSenderId: "441310049440",
        appId: "1:441310049440:web:3c4b6a1a9bae64523185c5"
    });

    export const db = getFirestore();